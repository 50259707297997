.pageTitle{
    font-size: 30px;
    margin: 15px;
    cursor: pointer;
}
.DashContainer{
    height: 100%;
    margin-top: 0px;
}
.columnFormInput{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.rowFormInput{
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.formInput{
    flex : 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    position: relative;
}

.formRadio > input {
    margin-right: 10px;
    accent-color: #526035;
}

.formRadio > span {
    opacity: 0.75;
    font-size: 14px;
}

.paymentOptionContainer{
    margin: 20px;
}
.paymentOptionContainer .title {
    font-size: 22px;
    margin-bottom: 20px;
}

.paymentOptionContainer > .columnFormInput {
    margin: 25px;
}

.paymentOptionContainer > .rowFormInput {
    margin: 25px;
}

.minButton{
    width: min-content !important;
    padding: 5px 15px !important;
    font-weight: normal !important;
    font-size: 15px;
    min-width: 150px;
}
.smallBtn{
    max-width: 160px !important;
    font-size: 14px;
}
.formInput{
    flex : 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    position: relative;
}
.formInput > div {
    opacity: 0.5;
    font-size: 15px;
    display: flex;
    align-items: center;
    min-width: 140px;
}
.formInput > input{
    flex: 1;
    background: none;
    outline: none;
    padding: 8px 10px;
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 10px;
    font-size: 15px;
    font-family: Poppins;
    max-width: 900px;
    margin: 0 auto;
}
.formInput > select{
    flex: 1;
    background: none;
    outline: none;
    padding: 8px 10px;
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 10px;
    font-size: 15px;
    font-family: Poppins;
    max-width: 800px;
    margin: 0 auto;
}
.referenceImage{
    width: 100%;
    text-align: right;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 10px;
    margin-right: 0px;
}
.referenceImage > img{
    width: 100%;
}
.buttonContainer{
    margin: 0 auto;
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    max-width: 300px;
    justify-content: space-evenly;
}

.dashHeader{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.accountMinButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;
}
.accountMinButton > input[type="checkbox"] {
    accent-color: #526035;
}
.graphDurationSelectorDiv{
    background: #FFFFFF;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
}
.graphDurationSelectorDiv > div{
    cursor: pointer;
    padding: 2px 10px;
    border-bottom: 1px solid rgba(82, 96, 53, 0.75);
    color: rgba(82, 96, 53, 0.8);
}
.graphDurationSelectorDiv > div:first-child{
    padding-left: 0px;
    margin-left: 10px;
}
.graphDurationSelectorDiv > div:last-child{
    padding-right: 0px;
    margin-right: 10px;
}
.graphDurationSelectorDiv > div.active{
    border-bottom: 3px solid rgba(82, 96, 53, 1);
    color: rgba(82, 96, 53, 1);
    font-weight: bold;
    font-size: 11px;
}