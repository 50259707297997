.manageAccountHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.manageAccountHeader > .title {
    margin-bottom: 0px !important;
}
.manageAccountHeader > button {
    max-width: 180px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.accountsContainerC{
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-height: 80%;
    overflow: auto;
}
.account{
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: space-between;
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 10px;
    padding: 20px;
    max-height: 110px;
}
.account > .leftDiv > .accountTitle > span{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
}
.account > .leftDiv > .accountTitle{
    font-size: 20px;
    font-weight: bold;
    color: rgba(0,0,0,0.3);
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.account > .leftDiv > .accountTitle > p{
    font-size: 10px;
    font-weight: normal;
    color: #37441e;

}
.account > .leftDiv > .endingWith{
    font-size: 13px;
    color: rgba(0,0,0,0.4);
}
.account > .rightDiv {
    position: relative;
}
.account > .rightDiv > .primaryTag{
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 10px;
    background: #e3e580;
}
.account > .rightDiv > .editLink{
    bottom: 0px;
    position: absolute;
    right: 0px;
    color: #526035;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
}