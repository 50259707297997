.basicAccountDetails{
    margin: 20px;
}
.currentAccountActionTitle{
    font-size: 22px;
}
.currentAccountActionSubTitle{
    font-size: 15px;
    opacity: 0.4;
    margin: 5px;
}
.accountSetupProgressTracker{
    margin-top: 10px;
}
.accountSetupProgressTracker > label {
    font-size: 14px;
    font-style: italic;
    opacity: 0.6;
}
.accountSetupProgressTracker > .setupProgressBar{
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 8px;
}
.setupProgressBar > div {
    height: 25px;
    width: 150px;
    background: rgba(0,0,0,0.1);
    border-radius: 5px;
}
.setupProgressBar > div > div{
    height: 25px;
    background: #fcaf59;
    border-radius: 5px;
    width: 0;
}
.accountSetupForm {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.rowFormInput{
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.columnFormInput{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.formInput{
    flex : 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    position: relative;
}
.formInput > div {
    opacity: 0.5;
    font-size: 15px;
    display: flex;
    align-items: center;
    min-width: 140px;
}
.formInput > input{
    flex: 1;
    background: none;
    outline: none;
    padding: 8px 10px;
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 10px;
    font-size: 15px;
    font-family: Poppins;
    max-width: 900px;
    margin: 0 auto;
}
.pageSubmit{
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 310px;
    align-self: flex-end;
}
.accountTypeContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap : 80px;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
}
.typeBox{
    max-width: 250px;
    padding: 20px 25px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.typeTitleM{
    font-size: 19px;
}
.typeDescriptionM{
    font-size: 14px;
    text-align: left;
    margin: 20px 0px;
    font-style: italic;
}
.typeSelectButton{
    padding: 10px;
    font-size: 13px;
    border-radius: 8px;
    cursor: pointer;
}
.typeSelectButton:hover{
    opacity: 0.85;
}
.primaryBackground{
    background: #526035;
    color: #FFFFFF;

}
.primaryBorder{
    border: 5px solid #526035;
}
.highlightBackground{
    background: #e3e580;
    color: #000000;

}
.highlightBorder{
    border: 5px solid #e3e580;
}

.formInput > .iconFormInput {
    opacity: 1 !important;
    flex: 1;
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 10px;
    margin: 0 auto;
    justify-content: center;
}

.formInput > .iconFormInput > svg {
    margin-left : 10px;
}

.formInput > .iconFormInput > input{
    padding: 8px 10px;
    font-size: 15px;
    font-family: Poppins;
    flex: 1;
    outline: none;
    background: none;
    border: none;
}

.searchDatalist {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 96%;
    margin: 0 auto;
    top: 40px;
    list-style-type: none;
    padding-left : 0px;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.075);
    transition: height 300ms ease-in-out;
    display: none;
    z-index: 11;
}

.searchDatalist > li {
    background: rgba(247, 247, 247, 1);
    padding: 15px 15px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    font-size: 13px;
    color: #000000;
    cursor: pointer;
}

.searchDatalist > li:hover {
    background: rgba(241, 241, 241, 0.95);
}
.formInput > .formRadio {
    opacity: 1 !important;
}

.formInput > .formRadio > label , .formInput > .formRadio > span {
    font-size : 13px;
}

.formInput > .formRadio > input {
    margin-right: 10px;
    accent-color: #526035;
}

.formInput > input[data-div="min-width"] {
    flex: auto;
    max-width: 200px;
    margin: 0px;
}
div[div-width="toggle"] {
    width: 200px;
}

.accordian{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    transition: all 300ms;
}

.accordianBox{
    transition: all 250ms ease-in-out;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    padding-bottom: 20px;
    position: relative;
}
.accordianBoxContainer{
    margin-top: 10px;
    padding: 0px 20px;
}
.accordianTitle{
    position: relative;
    font-size: 14px;
    cursor: pointer;
}
.removeUnit{
    position: absolute;
    right: 50px;
    cursor: pointer;
}

.renterDetails{
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.renterDetails > .leftPane{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

 .renterInfo{
    font-size: 14px;
}
 .renterInfo > label{
    font-weight: bold;
    color: rgba(0,0,0,0.45);
}
.renterInfo > span{
    color: rgba(0,0,0,0.75);
}

.renterInfo > .input {
    display: inline-block;
    background: rgba(0,0,0,0.05);
    border-radius: 10px;
    border: none;
    outline: none;
    width: 500px;
    height: 250px;
    resize: none;
    padding: 10px;
    font-size: 14px;
}

.columnFormInput > .renterInfo {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: row;
}

.titleBold{
    font-size: 14px;
    margin: -10px 0px;
    margin-left: 40px;
    font-weight: bold;
}
.editAddress{
    display: flex;
    flex-direction: row;
    gap: 25px;
}
.editAddress{
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.editAddress > svg { 
    cursor: pointer;
}
.subMessage{
    font-size: 15px;
    font-weight: bold;
    margin-left: 40px;
}
.multiUnitContainer{
    margin: 20px 40px;
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.multiUnitContainer  .unitContainer {
    padding: 15px 30px;
    box-shadow: 0 2px 2px 2px rgba(0,0,0,0.075);
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
}
.multiUnitContainer  .unitContainer:hover {
    background: #e3e58022;
}

.accountPopupDivMessage {
    font-size: 14px;
    padding: 5px;
}
.propertyHolder{
    margin-bottom: 30px;
}
.propertyHolder > div:first-child {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 10px;
}