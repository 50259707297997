.rightsidenav{
    height: 100%;
    width: 260px;
    position: fixed;    
    top: 100px;
    right:30px;
    text-align: center;
    background-color: #FFFFFF; 
}
.rightsidebar {
    top:30%;
    right:30px;
    text-align: center;
    padding: 0;
    width: 200px;
    background-color:#FFFFFF;
    position: fixed;
    height: 100%;
    overflow: auto;
  }
  .notificationBox{
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }
  .notificationBox > .title {
    font-weight: bold;
    margin: 5px 15px;
  }
  .notifi-icon{
    display: flex;
    width:40px;
    height: 45px;
    align-items: center;
    border-radius: 10px;
    margin-right:10px;
    
  }
  .notification-message{
    border: 1px solid rgba(0,0,0,0.075);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
  }
  .readMark{
    width: 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #526035;
  }
  .notice{
    flex: 1;
    padding: 5px;
  }
  .notificationHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1px;
  }
  .notificationHeader > .messsage {
    font-size: 12px;
    text-wrap: wrap;
    font-weight: bold;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 245px;
  }

.notificationHeader > .time{
  color : rgba(0,0,0,0.25);
  float: right;
  font-size: 12px;
}
.notificationMain{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.notificationMain > .notificationDescription{
  flex : 1;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}
.viewNotification{
  font-size: 12px;
  color: #185CC3;
  cursor: pointer;
}
.noticeContainer{
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  padding-right: 5px;
}