.signInTitleWrapper{
    margin: 15px 20px;
}
.signInTitleWrapper > label {
    font-size: 40px;
    font-weight: bold;
}
.signInTitleWrapper > p {
    font-size: 15px;
}
.signInForm{
    width: 100%;
}
.inputPhone{
    display: flex;
    box-sizing: border-box;
    margin: 0px 25px;
}
.inputBoxPhone{
    width: 100% !important;
    border-radius: 8px !important;
}
.signInInput{
    position: relative;
    border-radius: 5px;
    border: 1px solid #526035;
    margin: 25px 20px 0px 20px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
}
.signInInput > label{
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 14px;
    background: #FFFFFF;
    padding: 1px 5px;
    color: #15180e;
}
.signInInput > input {
    flex : 1;
    height: 38px;
    border: none;
    outline: none;
    font-size: 18px;
    font-family: Poppins;
}
.message {
    transition: all 100ms linear;
    font-size: 10px;
    letter-spacing: 1px;
    margin-left: 20px;
    margin-bottom: -10px;
    display: none;
}
.error {
    color : #c53939
}
.success {
    color : #24c35b
}
.hr{
    width: 80%;
    margin: 0 auto;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #c5c5c5;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
}
.hr > span {
    background: #FFFFFF;
    padding: 4px;
}
.tncMessage{
    font-size:9px;
    text-decoration: underline;
    color: #737373;
    margin-left: 20px;
}
.signLinkContainer{
    text-align: center;
    margin: 20px 0;
    font-size: 12px;
}

.loginLinks{
    color: #15180e;
    font-weight: bold;
}
.otpContainer{
    display: flex;
    flex-direction: row;
    margin: 10px 20px;
    justify-content: space-between;
}

.maskInputContainer{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.forgotPasswordLink{
    margin: 15px 25px;
    margin-bottom: -10px;
    font-size: 14px;
    color: #2B84D4;
    cursor: pointer;
}