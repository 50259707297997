.header {
  padding: 10px 40px;
  background: #ffffff;
}
.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logo {
  font-size: 60px;
  color: #000;
}

.ellipse {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  border-radius: 100px;
  background:linear-gradient(to left,#F76B6B,#FFA24B);
  color: #ffffff;
  font-family: Cairo;
  font-size: 31px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
}

  
.topbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #ffffff;
  border: none;
  outline: none;
  margin-right: 20px;

}
.icon-link__badge{
  position: absolute;
  top:-2px;
  right: -0px;
  width: 25px;
  height: 25px;
  background:#FF5E5E;
  color: #ffffff;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
