.paymentDashboard{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    height: 100%;
}
.infoContainer{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}
.balanceInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}
.balanceInfo > .leftDiv > span{
    font-size: 20px;
    font-weight: 950;
}
.balanceInfo > .leftDiv > p{
    font-size: 15px;
    opacity: 0.5;
    margin: 10px;
}
.balanceInfo > .rightDiv {
    min-width: 130px;
}
.balanceInfo > .rightDiv > span{
    font-size: 45px;
    color: #526035;
    letter-spacing: 1px;
}
.infoButton{
    width: 100% !important;
    max-width: 400px;
    margin: 10px !important;
    font-size: 13px !important;
    min-width: auto;
    border-radius: 8px !important;
    padding: 5px 25px !important;
}
.paymentRadio {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.paymentRadio > span {
    padding-left: 20px;
    margin-top: 3px;
}
.paymentRadio > p {
    padding-left: 5px;
    margin: 0px;
    margin-top: 3px;
    font-size: 13px;
    color: #A5A5A5;    
    text-wrap: nowrap;
}
.paymentModeName{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}
.paymentModeName > p {
    font-size: 10px;
    padding-left: 5px;
    text-transform: capitalize;
}
.row{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.fullWidth{
    width: 100%;
}
.formInputStripe{
    min-width: 250px;
    display: block !important;    
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    overflow: hidden;
}