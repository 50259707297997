.DashContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex: 1;
  margin-right: 25px;
}
.DashContainer > span {
  font-size: 32px;
}

.miniGraph{
  height: 100%;
  height: 150px;
  min-width: 150px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.miniGraphBar{
  width: 25px;
  color: #212529;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
}
.miniGraphBarText{
  font-size: 12px;
}
.miniGraphBarTextBottom{
  font-size: 10px;
}
.miniGraphBar:nth-child(odd) > .miniGraphBarFill{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #ced06d;
  width: 100%;
}
.miniGraphBar:nth-child(even) > .miniGraphBarFill{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #526035;
  width: 100%;
}