.mainTableContainer{
    width: 100%;
    margin-top: 10px;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 10px;
    flex: 1;
    overflow: hidden;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
}
.onlyTableDiv {
    overflow-y: auto;
    flex: 1;
    margin-top: 30px;
}
.tableHeader{
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.tableButton{
    max-width: 140px;
    font-size: 12px;
    border-radius: 8px;
    padding: 8px 12px !important;
    border: 1.5px solid;
}
.tableTitle {
    font-size: 14px;
    font-weight: bolder;
}
.table{
    width: 100%;
}
.table > thead{
    border-bottom: 2px solid rgba(0,0,0,0.08);
    position: sticky;
    top: 0;
    box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
}
.table > thead > tr > th {
    font-size: 13px;
    font-weight: 200;
    padding: 8px 25px;
    cursor: pointer;
}
.table > thead > tr > th:hover {
    background: #f4f4f4;
}
.table > tbody > tr > td {
    font-size: 12px;
    font-weight: 200;
    padding: 18px 25px;
}
.table > tbody > tr {
    border-bottom: 1px solid rgba(0,0,0,0.05);
}
.tablePropertyColumn{
    max-width: 400px;
    font-size: 11px;
    font-weight: bold;
}
.tablePropertyColumn > p{
    font-weight: normal;
    margin-top: 3px;
}
.tableRenterColumn > p {
    font-size: 10px;
    color: #949494;
    margin-top: 3px;
}

th:last-child .filterCustom{
    right: -40px !important;
    left: auto !important;
}