.DashContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex: 1;
  margin-right: 25px;
}
.DashContainer > span {
  font-size: 32px;

}
.renterTitle{
  font-size: 32px;
  margin-bottom: 20px;
}
.renterDescription{
  font-size: 18px;
  margin: 15px;
}
.role-container{
  display: flex;
  flex-direction: row;
  gap:20px;
  width:100%;
  justify-content: space-between;
}
@media  only screen and (min-height: 1000px) {
  .role-container{
    height: 100%;
    max-height: 300px;
  }
}
@media  only screen and (min-height: 1100px) {
  .role-container{
    height: 100%;
    max-height: 400px;
  }
}