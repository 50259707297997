.statusLabel{
    padding: 5px 5px;
    color: #FFFFFF;
    font-size: 12px;
    width: fit-content;
    border-radius: 8px;
    width: 80px;
}
.on-time {
    background: #ced06d;
}

.late {
    background: #e68a7a;
}

.partial {
    background: #ddba6d;
}
