.notification{
    width: 100%;
    margin: 10px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.075);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}
.smallNot{
    padding: 14px;
}
.smallNot > img{
    width: 40px;
}
.notification > .notificationMessage {
    color: #000;
    font-size: 14px;
    font-style: italic;
}