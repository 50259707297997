.manageAccountHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.manageAccountHeader > .title {
    margin-bottom: 0px !important;
}
.manageAccountHeader > button {
    max-width: 180px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.accountsContainer{
    margin-top: 20px;
    margin-left: 10px;
}
.accountPr{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    padding: 20px;
    margin-top: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.6);
    padding-bottom: 30px;
}
.accountPr > .leftDiv > .accountTitle{
    font-size: 20px;
    font-weight: bold;
    color: rgba(0,0,0,0.85);
    margin-bottom: 20px;
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.accountPr > .leftDiv > .endingWith{
    font-size: 13px;
    color: rgba(0,0,0,0.4);
}
.accountPr > .rightDiv {
    position: relative;
}
.accountPr > .rightDiv > .primaryTag{
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 10px;
    background: #e3e580;
}
.accountPr > .rightDiv > .editLink{
    top: 0px;
    position: absolute;
    right: 0px;
    color: rgba(0,0,0,0.85);
    font-size: 12px;
    text-transform: capitalize;
    cursor: pointer;
}