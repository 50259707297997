.miniCheckboxCustom{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    position : relative;
    font-size: 12px;
    cursor: pointer;
    margin: 2px;
}
.miniCheckboxCustom > input{
    position: relative;
    flex-shrink: 0;
    outline: none;
    border: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 2px;
}
.miniCheckboxCustom > svg {
    position: absolute;
    left: 2px;
    top: -2px;
    width: 8px;
    height: 8px;
    margin-top: 3px;
}
.miniCheckboxCustom > label{
    cursor: pointer;
    color: #737373;
}