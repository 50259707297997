
.signInButton{
    transition: all 100ms linear;
    padding: 15px 0px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex: 1;
    border: none;
    background: none;
    font-family: Poppins;
    width: -webkit-fill-available;
    margin: 20px;
    max-height: 60px;
}

.highlighted{
    background: #e3e580;
    color: #000000;
    border-radius: 12px;
}

.highlighted:hover{
    opacity: 0.9;
}

.primary{
    background: #526035;
    color: #FFFFFF;
    border-radius: 12px;
}

.primary:hover{
    opacity: 0.9;
}

.secondary{
    border: 1px solid #526035;
    color: #526035;
    border-radius: 12px;
}

.secondary:hover{
    opacity: 0.9;
    background: #5260351a;
}
