.sidenav{
    width: 260px;
    text-align: center;
    background-color: #FFFFFF;
    position: sticky;
}

.sidebar {
    text-align: center;
    padding: 40px 10px;
    width: 200px;
    background-color:#FFFFFF;
    height: 100%;
    overflow: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .sidebar a {
    display: block;
    color:#A5A5A5;
    padding: 12px;
    text-decoration: none;
    text-align:start;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;

  }
 
  .sidebar a.active {
    background-color: #F4F8EB;
    color:     #526035;
    border-radius: 10px;
  }
  
  .sidebar a:hover:not(.active) {
    background-color:#F4F8EB;
    color: #526035;
    border-radius: 10px;
  }
  
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }

  .helpbox{
    width: 480px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
  .helpboxHeader{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  .helpboxHeader > img {
    width: 100px;
  }
  .helpTitle > p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #526035;
  }
  .helpTitle > span {
    font-size: 16px;
  }
  
  .helpbox > textarea {
    margin: 0px 25px;
    padding: 10px 15px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.25);
    font-size: 14px;
    height: 170px;
    resize: none;
    outline: none;
    width: 90%;
  }
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  }

