.loaderContainer{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
}
.loaderContainer > img {
    width: 120px;
}