.graphContainer{
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 10px;
    min-width: 500px;
}
.graphHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 5px 10px;
}
.graphHeader > .graphTitle {
    font-size: 15px;
    font-weight: bold;
}
.graphHeader > .graphAmount {
    font-size: 18px;
    color: #ced06d;
    font-weight: bold;
}
.graph{
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0 auto;
}
.graph > .yAxisLabel {
    font-size: 9px;
    color: rgba(0,0,0,0.4);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.graphBox{
    position: relative;
    width: 100%;
    height: 100%;
    animation: all 250ms ease-in-out;
}
.graphBackGround{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.graphBackGround > div {
    border-bottom: 1px dashed rgba(0,0,0,0.1);
}
.graphForeGround{
    position: relative;
    z-index: 22;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.barBox{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    position: relative;
    transition: all 0.3s ease-out;
}
.barBox > .xAxisLabel{
    text-align: center;
    font-size: 9px;
    color: rgba(0,0,0,0.4);
    max-width: 60px;
    text-wrap: nowrap;
}

.barBox > .xAxisLabelMin{
    text-align: center;
    font-size: 8px;
    color: rgba(0,0,0,0.4);
    max-width: 30px;
}
.bar{
    border-radius: 10px;
    height: 100%;
    width: 45px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
}
.barMin{
    border-radius: 5px;
    height: 100%;
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
}

.barBox:nth-child(odd) > .bar, .barBox:nth-child(odd) > .barMin{
    background: #e6e6d4;
}
.barBox:nth-child(even) > .bar, .barBox:nth-child(even) > .barMin{
    background: #cdd9b6;
}
.barFill{
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    transition: all 0.3s ease-out;
}
.barFillMin{
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.barBox:nth-child(odd) > .bar > .barFill, .barBox:nth-child(odd) > .barMin > .barFillMin{
    color: #000000;
    background: #ced06d;
}
.barBox:nth-child(even) > .bar >  .barFill, .barBox:nth-child(even) > .barMin >  .barFillMin{
    background: #526035;
    color: #FFFFFF;
}
.barFill > span {
    font-size: 10px;
    margin-top: 5px;
    font-weight: 700;
}
.barFillMin > span {
    font-size: 10px;
    color: #FFFFFF;
    margin-top: 5px;
    font-weight: 700;
}

.barBox > span{
    font-size: 10px;
}
.hoverGraphBarDataToolTip{
    z-index: 11;
    display: flex;
    border-radius: 10px;
    background: rgba(0,0,0,0.6);
    padding: 10px;
    min-width: 80px;
    position: absolute;
    flex-direction: column;
    color: #FFFFFF;
    font-size: 12px;
    gap: 15px;
    transform: translateY(-50%);
    top: 40%;
    transition: 0.3s;
    transition: all 0.3s ease-out;
    visibility: hidden;
    opacity: 0;
}
.hoverGraphBarDataToolTip > .topData{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 15px;
    font-weight: bold;
}
.hoverGraphBarDataToolTip > .topData > .barDate {
    min-width: 60px;
}
.hoverGraphBarDataToolTip > .bottomData{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 3px;
    font-size: 10px;
    font-weight: bold;
}
.hoverGraphBarDataToolTip > .bottomData > .incomingAmount {
    font-size: 14px;
    font-weight: bold;
}

.barBox:hover .hoverGraphBarDataToolTip{
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-out;
}