.profileContainer{
    min-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
    gap: 10px;
}
.profileContainerHeader{
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    gap: 15px;
}
.inputPhoneNew{
    display: flex;
    box-sizing: border-box;
}
.inputBoxPhonenew{
    width: 100% !important;
    border-radius: 8px !important;
    padding: 10px !important;
    padding-left: 50px !important;
}
.inputPhoneNew .special-label{
    display: none !important;
}
.pCTitle{
    flex: 1;
    color: #526035;
}
.pCEdit{
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 5px;
    cursor: pointer;
    font-weight: 400;
}
.profileMainContainer{
    padding: 5px 10px;
    gap: 15px;
}
.profileMainContainer.rowC {
    align-items: center;
}
.profileItem{
    font-size: 14px;
}
.profileItemTitle{
    font-size: 11px;
    color: rgba(0, 0, 0, 0.5);
}
.profileItemHeading{
    font-size: 14px;
    font-weight: bold;
    margin-top: 2px;
}
.profileItemSubheading{
    font-size: 12px;
}
.unbold{
    font-weight: 400 !important;
}

.profileItemHeading > input {
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 5px 10px;
    width: 100%;
}