
.otpContainer > input {
    outline: none;
    user-select: none !important;
    width: 50px;
    height: 50px;
    font-weight: bold !important;
    border: none;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    margin: 0;
    border-bottom: 1px solid #15180e;
}
.otpContainer > input:focus {
    background: rgb(81, 94, 54, 0.04);
}

.otpContainer > input:active {
    background: rgb(81, 94, 54, 0.04);
}

.otp{
    width: 95%;
    margin: 0 auto;
}
.otpForm {
    margin-bottom: 40px;
}
.otpForm > .otpContainer{
    margin-bottom: 40px;
}

.otpFooter{
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    color: #737373;
}
.otpFooter > span{
    font-weight: bold;
}
.activeOTPButton {
    color: #15180e;
    cursor: pointer;
}
.inActiveOTPButton {
    color: #999999;
    cursor: not-allowed;
}