html{
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family: "Poppins", Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}
#root{
  height: 100%;
}
a{
  text-decoration: none;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.fadeIn {
  animation: inAnimation 350ms ease-in;
}

.fadeOut {
  animation: outAnimation 350ms ease-out;
}

@keyframes fillUp {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes fillDown {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.fillUp {
  animation: fillUp 700ms ease-in-out;
}

.fillDown {
  animation: fillDown 70ms ease-in-out;
}

main{
  margin: 0 auto;
  background: none;
}
.mainPanelOld{
  margin: 0 auto;
  padding-top: 15px;
  width: 85%;
  max-width: 1200px;
}
.mainPanel{
  margin: 0 auto;
  padding-top: 15px;
  width: 85%;
  max-width: 1200px;
  overflow: hidden;
}
.mainPanel > span {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 62px;
}
.titleMessage{
  font-size: 20px;
  margin: 0 auto;
  max-width: 800px;
}
.formTable{
  width: 100%;
  border: none;
  padding: 5px;
  margin-top: 10px;
}
.formTable > tr > td:first-child {
  font-size: 14px;
  opacity: 0.5;
  text-align: right;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.formTable > tr > td:nth-child(2) > div > input{
  min-width: 350px;
}

@media print {
  body * {
    visibility: hidden;
  }

  #printablediv * {
    visibility: visible;
  }
  #printablediv{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .customWrapper {
    position: unset !important;
  }
}

.scroller::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}
.scroller::-webkit-scrollbar-thumb {
  background-color: #00000026;
  border-radius: 50px;
}
.scroller::-webkit-scrollbar-track {
  background: 0 0;
}
.rowC{
  display: flex;
  flex-direction: row;
}
.columnC{
  display: flex;
  flex-direction: column;
}