.wrapperContainer {
  padding: 15px;
  border-radius: 10px;
  background: #ffffff;
  width: fit-content;
  margin: 0 auto;
  margin-top: 20px;
  min-width: 200px;
  max-width: 1000px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
}
.closePopup {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.customWrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: #ffffff;
  min-width: 350px;
  padding: 15px;
  margin: 10px;
  width: min-content;
  position: relative;
  overflow: hidden;
}

.popupContainer {
  z-index: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
}
.sidePopupContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  justify-content: right;
  align-items: center;
  z-index: 100;
}
.paymentHeader {
  background: #526035;
  height: 70px;
  width: 100%;
}
.paymentHeader > label {
  color: #ffffff;
  font-size: 20px;
  margin-top: 20px;
  margin-left: 20px;
}
.paymentBody {
  width: 100%;
  height: 510px;
  background: #ffffff;
  padding-left: 0px;
  padding-top: 20px;
}
.paymentFooter {
  background: #526035;

  height: 30px;
  width: 100%;
}
.bodyTitle {
  font-size: 25px;
  font-weight: bold;
}
.detailsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 18%;
  padding-left:38px;
}
.details {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1), 0 2px 0px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
}
.countTenants {
  font-size: 30px;
  padding: 5px;
  padding-bottom: 0px !important;

  color: #526035;
  font-weight: bold;
}
.tenants {
  font-size: 12px;
  padding: 5px;
  padding-top: 0px !important;
  padding-bottom: 15px !important;
  
}
.hr2 {
  width: 100%;
  border: 3px solid #cdcdcd;
  
}
.grid-container {
  display: flex;
  /* grid-template-columns: auto auto auto; */
  padding: 10px 10px 10px 30px;
  gap: 10px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}
.grid-item > .disabled{
    font-weight: 400;
    color: #A5A5A5;
}
.owe-container{
    display: flex;
    flex-direction: row;
    gap: 14%;
    justify-content: flex-start;
    padding-bottom:3%;
    padding-left:30px;
}