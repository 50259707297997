.toggleContainer{
    width: 30px;
    height: 16px;
    border-radius: 50px;
    box-sizing: border-box;
    display: flex;
    align-self: center;
    transition: all 350ms ease-in-out;
    cursor: pointer;
}

.toggleContainer > div {
    margin: 2px 3px;
    width: 12px;
    height: 12px;
    border-radius: 50px;
}

.unSelectedContainer {
    background: rgba(0, 0, 0, 0.15);    
    justify-content: start;
}

.unSelectedContainer > div {
    background: rgba(255, 255, 255, 1);
}

.selectedContainer {
    background: #526035;
    justify-content: end;
}

.selectedContainer > div {
    background: rgba(255, 255, 255, 0.9);    
}