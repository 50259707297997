.parent{
    padding: 40px;
    max-width: 1500px;
    margin: 0 auto;
}
.parent > section:nth-child(2){
    margin-top: 50px;
}

.parent > section > h4 {
    font-weight: bold;
}

.parent > section > h5 {
    font-size: 12px;
    opacity: 0.5;
}
.parent > section > div > p {
    font-size: 14px;

}
.parent > section > div > ol > li{
    margin-top: 15px;
}
.parent > section > div > ol > li > div {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: bold;
}
.parent > section > div > ol > li > ol {
    font-size: 14px;
}
.parent > section > div > ol > li > ol > li{
    margin-top: 5px;
}