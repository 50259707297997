.btnAdd {
  width: 200px !important;
  height: 47px !important;
}
.view {
  font-size: 12px;
  float: right;
}
.prop-row {
  display: flex;
  flex-direction: row;
  column-gap: 5%;
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
}
.prop-count {
  font-weight: 700;
  font-size: 20px;
}
.signInButtonCustom {
  font-size: 13px;
  max-width: 130px !important;
  height: 40px;
}
.pageBtn {
  display: flex;
  gap: 30px;
  align-self: flex-end;
}
.currentPropertyActionTitle {
  white-space: nowrap;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.currentPropertyActionTitle > label {
  margin-top: 3px;
}
.fade-in {
  opacity: 0.5;
  font-size: 12px;
  font-weight: 300;
}
.wrapperContainer {
  margin-top: 0px !important;
  margin: 0 auto;
}
.home-icon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}
.status-container {  
  margin-top: 18px;
  width: 53%;
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-container label {
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
}
.status {
  color: #38e25d;
  font-size: 30px;
}
.userAddress {
  font-weight: normal !important;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 5px;
  max-width: 500px;
  white-space: break-spaces;
}
.cash-icon {
  width: 40px;
  height: 45px;
}
