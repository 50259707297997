.topDivContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    max-height: 255;
}
.topDivContainer > .leftDiv {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.subDash{
    background: #FFFFFF;
    border-radius: 10px;
    max-width: 400px;
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    cursor: pointer;
}
.subRow {
    flex-direction: row;
}

.subColumn {
    flex-direction: column;
}
.infoDiv > .subDashTitle {
    font-size: 15px;
    font-weight: bold;
}
.infoDiv > .subDashDesc{
    font-size: 11px;
    max-width: 180px;
    color: rgba(0,0,0,0.5);
    margin: 2px;
}
.pendingRentHint{
    font-size: 30px;
    text-align: right;
    font-weight: 600;
    color: #e68a7a;
}

.topDivContainer > .rightDiv{
    flex: 1;
}
.viewOutstanding {
    font-size: 10px;
    text-align: right;
    margin-bottom: 2px;
    margin-right: 10px;
  }