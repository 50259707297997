.checkboxCustom{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    position : relative;
    font-size: 12px;
    cursor: pointer;
    margin: 10px 20px;
}
.checkboxCustom > input{
    position: relative;
    flex-shrink: 0;
    outline: none;
    border: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 4px;
}
.checkboxCustom > svg {
    position: absolute;
    left: 2px;
    top: -2px;
    width: 16px;
    height: 16px;
    margin-top: 4px;
}
.checkboxCustom > label{
    cursor: pointer;
    color: #737373;
}