.view {
  font-size: 12px;
  float: right;
  margin-bottom: 0px;
  margin-right: 10px;
}
.summary {
  width: 200px;
  height: 70px;
  top: 551px;
  left: 835px;
  background-color: #d9d9d9;
  text-align: center;
  padding-top: 20px;
}

.prop-typebox {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.prop-typebox > div {
  max-width: 250px;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.textDesign {
  width: 10px;
  height: 6px;

  font-family: Poppins;
  font-size: 11px;
  line-height: 18px;
  color: #000000;
}
.typeTitle {
  font-size: 13px;
  text-align: start;
  font-weight: 600;
}
.typeDescriptionCustom {
  font-size: 12px;
  text-align: left;
  margin: 20px 0px;
  font-style: Open Sans;
  color: #212529;
  opacity: 0.5;
}
.typeDescription {
  font-size: 10px;
  font-style: Open Sans;
  color: #a5a5a5;
}
.wrapperContainer {
  margin-top: 0px !important;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: #ffffff;
}

.circle-background {
  stroke: #f5f5f5;
}

.circle-progress {
  stroke: #d1d274;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 1.5em;
  font-weight: bold;
  fill: #202020;
}
.trophy-icon {
  width: 100px;
  height: 90px;
}

.celebration-icon {
  width: 60px;
  height: 60px;
}
.emptySpace{
  width: 60px;
  height: 60px;
}
.trophy-container {
  position: relative;
}
.reachedCount {
  position: absolute;
  color: #0bedc8fa;
  font-size: 40px;
  right: 40px;
  font-weight: 600;
}
.propRenter-typebox {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 110px;
  

}
.propRenter-typebox > div {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  padding: 1%;
  margin-left: 2%;
  text-align: center;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
}
.renterTypeTitle {
  font-size: 14px;
padding-top:10px;
padding-left:8px;
  font-weight: 700;

}