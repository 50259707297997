.Calendar-container {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3), 0 2px 0px 0 rgba(0, 0, 0, 0.15);
  margin: 0;
  background: #ffffff;
  max-width: 350px;
  min-width: 270px;
  max-height: 320px;
}

.CalHeader {
  display: flex;
  margin: 20px;
  color: #4A5660;
  justify-content: space-between;
  align-items: center;
}

.CalHeader button {
  font-size: 25px;
  color: #B5BEC6;
  ;
  border: none;
  cursor: pointer;
  background: #ffffff !important;
}

.CalBody {
  display: grid;
  grid-template-columns: repeat(7, 25px);
  font-size: 20px;
  color: #4A5660;
  margin: 0 auto;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  row-gap: 10px;
}

.box {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.weekdays {
  margin: 0 auto;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}

.weekdays .daySpecifier {
  color: #B5BEC6;
  text-align: center;
  font-size: 12px;
  width: 25px;
  text-transform: uppercase;
}

.box.empty {

  cursor: default;
}

.box.selected {
  background: #526035;
  border-radius: 50%;
  color: white;
}

.box.due {
  background: #E3E580;
}

.box.lateFee {
  background: #FF5E5E;
  color: white;
}

.popup-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-top: 10px;
  margin-left: 30px;
}

.OnTimePaymentTitle {
  font-size: 25px;
  margin-left: 10px;

}

.header5 {
  margin: 10px;
  margin-left: 20px;
}

.ImpDatesDescription {
  display: flex;
  flex-direction: row;
  gap: 25px;

}

.impDates {
  color: #A5A5A5;
  margin-top: 1%;
  font-size: 12px;
}

.greenIndicates{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4%;
}
.greenBox {
  padding: 5px;
  background: #E3E580;
  width: 14px;
  height: 14px;
}
.redIndicates{
  margin-top: 4%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.redBox {
padding:7px;
  background: #FF5E5E;
  width: 14px;
  height: 14px;
}

.hrLine {
  border: 2px solid #E3E3E3;
}

.paymentInput>.paymentRadio {
  opacity: 1 !important;
}

.paymentRadio>label,
.paymentRadio>span,
.paymentRadio>span {
  font-size: 13px;
  color: #A5A5A5;

}

.paymentRadio>input {
  margin-right: 10px;
  accent-color: #526035;
}

.PayOtherAmount {
  width: 50%;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid #A5A5A5;
  margin-left: 20px;
  outline: none;
  padding: 5px;
  font-size: 12px;
}

.paymentInput {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.rowPaymentAmount {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 5%;
}

.Amount {
  font-size: 24x;
  text-align: center;
}

.PaymentMethod-Container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 4%;
  padding: 0px;
}

.paymentMethod {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  align-items: center;
  width: 50%;
  border-radius: 10px;
  box-shadow: 0 6px 8px 4px rgba(0, 0, 0, 0.1), 0 2px 0px 0 rgba(0, 0, 0, 0.15);
  justify-content: space-between;
}

.VisaCard-Img {
  width: 100px;
  height: 40px;

}

.expDate {
  font-size: 12px;
  color: #A5A5A5;
  float: right;
  margin-left: 20px;
}

.btnPayRent {
  width: 100% !important;
  max-width: 150px;
  font-size: 13px !important;
  min-width: auto;
  border-radius: 8px !important;
  padding: 5px 25px !important;
  height: auto;
}

.PayRent {
  margin-top: 10%;
  margin-left: 4%;
}

.payment_container {
  height: 50%;
  overflow-y: scroll;
}
.impDatesTitle{
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  margin-top: 4%;
  margin-left: 3%;
}
.notePayment{
  margin-top:8%;
  margin-left: 6%;
}