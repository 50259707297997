.floatingInput{
    border: 1px solid rgba(0,0,0,0.15);
    width: fit-content;
    padding: 5px 10px;
    border-radius: 10px;
    position: relative;
    transition: all 250ms ease-in-out;
    animation: all 250ms ease-in-out;
}
.floatingInput > input{
    min-width: 100%;
    max-width: 500px;
    padding-right: 25px;
    width: 425px;
    border: none;
    outline: none;
    z-index: 99;
    padding-top: 15px;
}
.floatingLabel{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
}
.floatingCheck{
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    right: 15px;
}